'use strict';

angular.module('enervexSalesappApp').controller('SettingsCtrl', function($scope, $stateParams, User, Auth, Flash, _, DrawingUtil) {
	$scope.errors = {};
	$scope.form = {};
	$scope.activeForm = $stateParams.activeForm ? Number($stateParams.activeForm) : 0

	$scope.changePassword = function() {
		$scope.submitted = true;
		clearFlash()
		Auth.changePassword($scope.user.oldPassword, $scope.user.newPassword).then(function() {
			$scope.message = 'Password successfully changed.';
		}).catch(function(err) {
			addFlashError("could not save new password")
			_.each(err.data.errors, function(error, field) {
				if (field == "hashedPassword"){
					field = "newPassword"
				}
				form[field].$setValidity('mongoose', false);
				$scope.errors[field] = error.message;
			});
		});
	};
	function clearFlash() {
		Flash.clear()
	}
	function addFlashError(obj){
		var message = null
		if (_.isString(obj)){
			message = obj
		} else if (obj.message) {
			message = obj.message
		} else if (obj.status) {
			message = obj.status + " " + obj.statusText;
		} else {
			message = "Error occurred " + obj 
		}
		
		Flash.create('warning', message, 0, {}, true);
	}
	function addFlashInfo(message){
		
		$scope.success = true;
		Flash.create('success', message, 0, {}, true);
	}

	var user = null
	$scope.palette = {}
	$scope.defaultPalette
	DrawingUtil.getDefaultPalette().then(function(paletteData) {
	    $scope.defaultPalette = paletteData;
	})

	Auth.getSession().then(function(result){
   		if (result.user){
   			user = result.user
   			$scope.palette = result.user.palette
   		}
   	})
   	$scope.resetDesignElement = function(applicationType, element){
		$scope.palette[applicationType][element] = null
		$scope.formChanged = true
	}

	$scope.updateUserColorPalette = function(){
		$scope.formChanged = true
	}
	$scope.savePalette = function(palette) {
		var payload = user
		payload.palette = palette
		User.update({
			id: user.id
		}, payload).$promise.then(function(result){
			$scope.success = true;
			Flash.create('success', '<strong>Success!</strong> Updated user color overrides.', 0, {}, true);
		}).catch(function(err){
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = (err.data && err.data.message) || "There was an error updating the user color overrides";
			Flash.create('danger',  '<strong>Error!</strong> '+message, 0, {}, true);
		});
	}
	$scope.colorPickerOptions = {
		required: false,
		disabled: false,
		inputClass: 'drawing-color',
		restrictToFormat: true,
		format: 'hexString',
		hue: false,
		lightness: true,
		alpha: false,
		swatch: true,
		swatchPos: 'left',
		swatchBootstrap: true,
		swatchOnly: false,
		round: true,
		pos: 'bottom right',
		inline: false,
		horizontal: false,
		reset: {
			show: true,
			label: 'Reset',
			class: '',
		},
	}
	$scope.eventApi = {
		onChange:  function(api, color, $event) {
			$scope.updateUserColorPalette()
		},
		onBlur: function(api, color, $event) {},
		onOpen: function(api, color, $event) {},
		onClose: function(api, color, $event) {},
		onClear: function(api, color, $event) {},
		onReset: function(api, color, $event) {},
		onDestroy: function(api, color) {},
	};

	$scope.applicationTypes = DrawingUtil.getApplicationTypes()
	$scope.triangleLengths = DrawingUtil.getTriangleLengths()
	$scope.triangleFonts = DrawingUtil.getTriangleFonts()
	$scope.lineOffsets = DrawingUtil.getLineOffsets()
	$scope.applicationType = $stateParams.applicationType ? DrawingUtil.setApplicationType($stateParams.applicationType) : $scope.applicationTypes[0].value
});